/*
 * @Author: xiaocai 2106553620@qq.com
 * @Date: 2022-09-06 10:36:34
 * @LastEditors: xiaocai 2106553620@qq.com
 * @LastEditTime: 2022-09-06 10:37:23
 * @FilePath: \H5\uova\src\views\home\detail\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '../../../utils/request/request.js';
export function airDrop(params) {
  return request({
    url: '/login/airDrop',
    method: 'get',
    params,
  });
}
