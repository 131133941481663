<!--
 * @Author: xiaocai 2106553620@qq.com
 * @Date: 2022-09-01 13:39:11
 * @LastEditors: caijing 2106553620@qq.com
 * @LastEditTime: 2022-12-26 10:55:47
 * @FilePath: \uova\src\views\home\detail\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="indexList">
    <div class="index">
      <!-- 详情页 -->
      <div class="xiangqing">
        <img class="video" src="../../../static/image/西有青年 00_00_00-00_00_30.gif" alt="" />
        <div class="xiangqinOne">
          <img src="../../../static/image/介绍框文字.png" alt="" />
        </div>
      </div>
      <div class="xiangqing2">
        <div>
          <img src="../../../static/image/左上.png" alt="" class="left_top">
          <img src="../../../static/image/右上.png" alt="" class="right_top">
          <h3>用户须知</h3>
          &nbsp;&nbsp;&nbsp;&nbsp;“北京市西城区青年联合会‘西有青年’数字徽章”
          全球限量发行700份，同一用户仅限参与一次活动。
          <br />
          <br />
          如何获得?
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;“北京市西城区青年联合会‘西有青年’数字徽章”？
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;数字徽章将以手机短信定向推送的形式发送给用户。
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;本次活动中获得“北京市西城区青年联合会‘西有青年’数字徽章”的用户，关注“青春西城”微信公众号，进入“微活动”专栏，进入H5链接，查看我的藏品。
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;本次活动中数字徽章及其他相应权益发放规则的最终解释权归北京市西城青年联合会所有
          <br />
          <br />
          <img style="width:100%;margin:0 auto" src="../../../static/image/横线.png" alt="">
          <h4>什么是数字藏品？</h4>
          &nbsp;&nbsp;&nbsp;&nbsp;本次发放的“西有青年”数字徽章是北京市西城区青年联合会专属定制的一种数字藏品，数字藏品是依托区块链技术，进行标识数字化的特定产品、艺术品和商品，包括但不限于数字画作、图片、音乐、视频、3D模型等各种样式
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;数字藏品的版权由发行方或原创者拥有，除另行取得版权拥有者书面同意外，用户仅可为个人收藏、学习、研究、欣赏及展示目的使用，不得将数字藏品用于任何商业用途。
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;数字藏品为虚拟数字商品，而非实物
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;每个数字藏品映射着特定区块链上的唯一序列号，不可篡改、不可分割，也不能互相替代，每一个数字藏品都代表特定作品、艺术品和商品限量发行的单个数字复制品，记录着其不可篡改的链上权利。
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;数字藏品与虚拟货品等同质化代币存在本质不同，没有特定作品、艺术品和商品的实际价值做支撑，也不具备支付功能等任何货币属性。因此，请勿对数字藏品进行炒作、场外交易、欺诈，或以任何其他非法方式进行使用
          <br />
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;出品单位:
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;北京市西城区青年联合会
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;支持单位:
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;北京红墙微视品牌文化传播有限公司
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;北京元隆雅图文化传播股份有限公司
          <img src="../../../static/image/左下.png" alt="" class="left_bottom">
          <img src="../../../static/image/右下.png" alt="" class="right_bottom">
        </div>
        <div class="detailList">
          <p>区块链:文昌链</p>
          <p>持有人编号:{{data.xcId}}</p>
          <p>
            合约地址：
            <span style="width: 100%; word-break: break-word; font-size: 14px">
              <!-- iaa1tpp4qjhgcf0y2uwky6rlu948rwqyc37vcjcxka -->
              {{ data.owner }}
            </span>
          </p>
          <p>
            铸造HASH:
            <span style="width: 100%; word-break: break-word; font-size: 14px">
              {{ data.mintHash }}
              <!-- 9B7A4AAF63D72C0FCE29720F4C3C99045778A6B85F0594000ABDDB2D0054F8BB -->
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { airDrop } from './index';
export default {
  name: 'UovaIndex',
  data() {
    return {
      data: [],
      phone: void 0,
      imgflag: false,
    };
  },
  mounted() {
    this.List();
    this.airDropList();
  },

  methods: {
    detailMap() {
      this.imgflag = true;
      console.log(this.imgflag);
    },
    List() {
      if (this.$route.params.phone) {
        this.phone = this.$route.params.phone;
      } else {
        this.phone = '';
      }
    },
    airDropList() {
      const phone = this.phone;
      airDrop({ phone: phone }).then(res => {
        this.data = res.data.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.indexList {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-image: url('../../../static/image/背景.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* color: #90454a; */
  /* background: #d73133; */
  box-sizing: border-box;
  .index {
    width: 100%;
    height: 100%;
    flex: 1;
    overflow-y: scroll;
    position: relative;
    .xiangqing {
      width: 90%;
      margin-left: 4%;
      height: auto;
      margin-top: 4%;
      background-image: url('../../../static/image/介绍框.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      .video {
        width: 95%;
        height: auto;
        margin: 10px;
        margin-left: 2.5%;
      }
      .xiangqinOne {
        width: 100%;
        line-height: 30px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        font-size: 15px;
        padding-bottom: 0.2%;
        img {
          width: 90%;
          height: auto;
          margin-left: 2%;
        }
      }
    }
    .xiangqing2 {
      width: 90%;
      height: auto;
      margin: 5%;
      margin-bottom: 5%;
      padding-bottom: 5%;
      background-image: url('../../../static/image/用户底框.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      font-size: 13px;
      position: relative;
      .left_top{
        width: 15px;
        position: absolute;
        left: 2%;
        top: 4%;
      }
      .right_top{
        width: 15px;
        position: absolute;
        right: 2%;
        top: 4%;

      }
      .left_bottom{
        width: 15px;
        position: absolute;
        left: 2%;
        bottom: 15%;
      }
      .right_bottom{
        width: 15px;
        position: absolute;
        right: 2%;
        bottom: 15%;
      }
      div {
        width: 90%;
        height: auto;
        line-height: 30px;
        margin-left: 5%;
        color: #fff;
        hr {
          width: 80%;
          color: #cfcfcf;
        }
        h3 {
          text-align: center;
          padding-top: 10%;
          font-size: 20px;
        }
        h4 {
          text-align: center;
          padding-top: 0%;
          font-size: 20px;
        }
      }
      .detailList {
        width: 90%;
        margin-left: 5%;
        padding-top: -10px;
        padding-bottom: 20px;
        height: auto;
        margin-top: 4%;
        display: flex;
        font-size: 13px;
        line-height: 25px;
        flex-direction: column;
        flex-wrap: wrap;
        color: #fff;
        p {
          margin: 0;
          padding: 10px 0;
        }
      }
    }
  }
}
</style>
